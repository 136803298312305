<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>订单详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="info-container">
      <a-row>
        <a-col><div class="info-title">基本信息</div></a-col>
      </a-row>
      <a-form :form="infoForm">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单编码" class="form-item">
              {{ info.orderSn }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单类型" class="form-item">
              {{ info.orderType }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单时间" class="form-item">
              {{ tsFormat(info.createTime) }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="经销商" class="form-item">
              {{ info.businessVenuName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="是否全款" class="form-item">
              {{ info.fullPaymentFlag ? "全款支付" : "分期支付" }}
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="商品总价" class="form-item">
              {{ "¥" + info.totalPayment }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单状态" class="form-item">
              {{ "交易成功" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="确认收货时间" class="form-item">
              {{ info.receivingTime ? tsFormat(info.receivingTime) : "--" }}
            </a-form-item>
          </a-col>

          <a-col v-if="info.orderType === '远期订单'" :span="8">
            <a-form-item label="下订月份" class="form-item">
              {{ formatMonth(info.monthOrderSkuDOList) }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="订单备注" class="form-item">
              <div>{{ info.memo ? info.memo : "-" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="receive-info">
      <div class="info-title">收货信息</div>
      <a-form :form="receiveForm">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收货人姓名" class="form-item">
              {{ info.consigneeName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人电话" class="form-item">
              {{ info.consigneeTel }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货地址" class="form-item">
              {{ info.consigneeFullAddress }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="commodity-info">
      <div class="commodity-title">
        <div class="info-title">商品信息</div>
      </div>
      <section>
        <a-table
          class="commodity-table"
          :data-source="tblData"
          :columns="tableColumns"
          :pagination="false"
          :loading="loading"
          row-key="skuId"
        >
          <div
            slot="skuImages"
            v-viewer
            slot-scope="text, record"
            class="img-container"
          >
            <div
              v-if="record.fileList && record.fileList.length > 0"
              class="img-container"
            >
              <img
                v-if="!record.fileList[0].thumbUrl"
                :src="imageBaseUrl + record.fileList[0].fileUrl"
                :data-source="imageBaseUrl + record.fileList[0].fileUrl"
                alt="产品图片"
                class="img-sku"
              />
              <img
                v-else
                :src="imageBaseUrl + record.fileList[0].thumbUrl"
                :data-source="imageBaseUrl + record.fileList[0].fileUrl"
                alt="产品图片"
                class="img-sku"
              />
            </div>
          </div>
          <template v-if="info.orderType !== '即时订单'" slot="title">
            {{ formatMonth(info.monthOrderSkuDOList) }}
          </template>
        </a-table>
      </section>

      <section
        v-if="info.logisticsVOList && info.logisticsVOList.length > 0"
        class="commodity-container"
      >
        <div v-if="info.logisticsVOList.length === 1">
          <div class="logistics">
            <div class="info-title">物流信息</div>
            <a-button
              @click="onDeliveryDetail(info.logisticsVOList[0].logisticsId)"
            >
              查看发货明细
            </a-button>
          </div>
          <a-row>
            <a-col :span="12" class="col">
              物流公司：{{ info.logisticsVOList[0].logisticsName }}
            </a-col>
            <a-col :span="12" class="col">
              快递单号：{{ info.logisticsVOList[0].logisticsSn }}
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" class="col">
              我的发货信息：{{
                info.logisticsVOList[0].senderFullAddress +
                  " " +
                  info.logisticsVOList[0].senderDetailAddress +
                  " " +
                  "," +
                  info.logisticsVOList[0].senderName +
                  " " +
                  "," +
                  info.logisticsVOList[0].senderTel
              }}
            </a-col>
            <a-col :span="12" class="col">
              联系电话：{{ info.logisticsVOList[0].logisticsTel }}
            </a-col>
          </a-row>
          <div class="line"></div>
        </div>
        <a-row v-if="info.logisticsVOList.length > 1">
          <a-col><div class="info-title">物流信息</div></a-col>
        </a-row>
        <div v-if="info.logisticsVOList.length > 1">
          <div
            v-for="(item, index) in info.logisticsVOList"
            :key="index"
            class="deliver-item"
          >
            <div class="item-title">
              第{{ toChinesNum(index + 1) }}批
              <a-button
                class="btn-detail"
                @click="onDeliveryDetail(item.logisticsId)"
              >
                查看发货明细
              </a-button>
            </div>
            <a-row>
              <a-col :span="12" class="col">
                物流公司：{{ item.logisticsName }}
              </a-col>
              <a-col :span="12" class="col">
                快递单号：{{ item.logisticsSn }}
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12" class="col">
                我的发货信息：{{
                  item.senderFullAddress +
                    " " +
                    item.senderDetailAddress +
                    " " +
                    "," +
                    item.senderName +
                    " " +
                    "," +
                    item.senderTel
                }}
              </a-col>
              <a-col :span="12" class="col">
                联系电话：{{ item.logisticsTel }}
              </a-col>
            </a-row>
            <div class="line"></div>
          </div>
        </div>
      </section>
    </section>
    <section class="bottom-holder"></section>
  </a-layout-content>
</template>

<script>
import { fetchUndeliveredDetail } from "../../services/OrderService";
import { tsFormat } from "../../components/DateUtils";
import { imageBaseUrl } from "../../services/HttpService";
import { formatList, toChinesNum } from "../../components/utils";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";

const tableColumns = [
  {
    title: "商品编码",
    dataIndex: "skuCode"
  },
  {
    title: "商品名称",
    dataIndex: "skuName"
  },

  {
    title: "商品图",
    dataIndex: "skuImages",
    scopedSlots: { customRender: "skuImages" }
  },
  {
    title: "商品数量",
    dataIndex: "purchaseQuantity"
  },
  {
    title: "已发货数量",
    dataIndex: "purchaseQuantity1"
  },
  {
    title: "装运总体积（m³）",
    dataIndex: "skuVolumeTotal"
  },
  {
    title: "装运总质量（kg）",
    dataIndex: "skuWeightTotal"
  }
];
export default {
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      tsFormat,
      formatList,
      imageBaseUrl,
      toChinesNum,
      infoForm: this.$form.createForm(this),
      receiveForm: this.$form.createForm(this),
      tblData: [],
      tableColumns,
      pagination: {
        hideOnSinglePage: false
      },
      step: 0,
      loading: false,
      isImportValue: false,
      firstButton: "驳回",
      secondButton: "通过",
      price: "",
      payWay: "",
      calculationForm: this.$form.createForm(this),
      orderSn: this.$route.params.orderSn,
      info: {},
      setStandardVisible: false,
      purchaseMinVolume: "",
      purchaseMaxVolume: "",
      purchaseMinWeight: "",
      purchaseMaxWeight: "",
      dateList: [],
      orderSkuList: [],
      firstPay: "",
      firstPayInfo: {},
      factoryId: localStorage.getItem("factoryId")
    };
  },
  mounted() {
    this.onDeliveredOrderDetail();
  },
  methods: {
    // 已交付订单详情
    onDeliveredOrderDetail() {
      this.loading = true;
      fetchUndeliveredDetail(this.orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.loading = false;
          const data = resp.data.data;
          this.info = data;
          let skuList = [];
          for (const key in data.monthOrderSkuDOList) {
            skuList = data.monthOrderSkuDOList[key];
          }
          skuList.forEach(item => {
            item.skuVolumeTotal = item.skuVolume * item.purchaseQuantity;
            item.skuWeightTotal = item.skuWeight * item.purchaseQuantity;
            item.purchaseQuantity1 = item.purchaseQuantity;
          });

          this.tblData = skuList;
        }
      });
    },
    // 发货明细
    onDeliveryDetail(logisticsId) {
      this.$router.push({
        name: "DeliveryDetails",
        params: { logisticsId: logisticsId, orderSn: this.orderSn }
      });
    },
    // 格式化月份展示
    formatMonth(form) {
      let str = "";
      for (const key in form) {
        str += `${key}`;
      }
      return str;
    },
    onBack() {
      this.$router.push({ name: "DeliveredOrder" });
    }
  }
};
</script>

<style scoped>
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
.button-container {
  display: flex;
}
.button-item {
  margin-right: 15px;
}
.step-container {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 10px 25px 10px;
}
/deep/ .ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  min-width: 180px;
}
/deep/ .ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 0 0 90px;
}
/deep/ .ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-left: 87px;
}
.info-container {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
}
.info-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.ant-row {
  display: flex !important;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: left !important;
  min-width: 80px !important;
}
/deep/ .form-item .ant-form-item-control {
  width: 90% !important;
}
.info-container /deep/ .ant-form-item {
  margin: 0;
}
.receive-info {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px;
}
.commodity-info {
  background-color: white;
  margin: 10px 20px 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px;
}
.commodity-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.commodity-table {
  margin-top: 20px;
  margin-bottom: 20px;
}
.bottom-holder {
  width: 100%;
  height: 70px;
}
.price-title {
  min-width: 80px;
}
.col {
  display: flex;
  align-items: center;
}
.finish {
  color: #3769ff;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 80px 0;
}
.finish img {
  margin-bottom: 20px;
}
.finish span {
  margin-left: 18px;
}
/deep/ .ant-table-title {
  display: flex;
  justify-content: center;
  background-color: rgba(55, 105, 255, 0.1);
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 120px !important;
}
/deep/ .ant-form-item-control {
  width: 90%;
}
.img-container {
  width: 100px;
  height: 100px;
}
.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.set-standard-container {
  display: flex;
  align-items: flex-end;
}
.input-standard {
  width: 100px;
  margin-right: 10px;
}
.standard-word {
  min-width: 120px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.set-standard-container-two {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}
.unit {
  width: 20px;
}
.img-sku {
  width: 100px;
  height: 100px;
}
.table-item {
  box-sizing: border-box;
  padding-bottom: 20px;
}
.deliver-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.item-title {
  box-sizing: border-box;
  padding: 12px 20px;
  background-color: #ecf0fe;
  display: flex;
  justify-content: center;
  position: relative;
}
.btn-detail {
  position: absolute;
  right: 20px;
  top: 7.5px;
}
.col {
  box-sizing: border-box;
  padding: 15px 0;
}
.logistics {
  display: flex;
  justify-content: space-between;
}
</style>
