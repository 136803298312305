<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>订单管理</a-breadcrumb-item>
        <a-breadcrumb-item v-if="isFromPd">
          <a @click="onBackPD">拼单订单</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item v-else>
          <a @click="onBack">未交付订单</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>订单详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <section class="info-container">
      <a-row>
        <a-col><div class="info-title">基本信息</div></a-col>
      </a-row>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单编号">{{ info.orderSn }}</a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单类型">{{ info.orderType }}</a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item v-if="info.orderType !== '即时订单'" label="下定月份">
              {{ dateMonth }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单时间">
              {{ tsFormat(info.createTime) }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="经销商">
              <div class="venu-name">
                <a :title="info.businessVenuName">
                  {{ info.businessVenuName }}
                </a>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="是否全款">
              <span v-if="info.fullPaymentFlag">全款支付</span>
              <span v-else>分期支付</span>
            </a-form-item>
          </a-col>
          <a-col v-if="!info.fullPaymentFlag" :span="8">
            <a-form-item label="本期商品总价">
              {{ info.totalPayment }}
            </a-form-item>
          </a-col>
          <a-col v-if="info.fullPaymentFlag && !info.porderSn" :span="8">
            <a-form-item label="商品总价">
              {{ info.totalPayment }}
            </a-form-item>
          </a-col>
          <a-col
            v-if="
              info.orderType === '远期订单' &&
                info.isThereAnAssociatedOrder &&
                !info.fullPaymentFlag
            "
            :span="8"
          >
            <a-form-item label="本期尾款">
              {{ info.currentBalancePayment }}
            </a-form-item>
          </a-col>
          <a-col
            v-if="
              !info.fullPaymentFlag && info.isThereAnAssociatedOrder === null
            "
            :span="8"
          >
            <a-form-item label="首付款">
              {{ info.downPayment }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单状态">{{ info.orderStatus }}</a-form-item>
          </a-col>
          <a-col v-if="info.porderSn" :span="8">
            <a-form-item label="交付周期">
              {{ info.orderCycle }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="订单备注" class="form-item">
              <div>{{ info.memo ? info.memo : "-" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="info-container">
      <a-row>
        <a-col><div class="info-title">收货信息</div></a-col>
      </a-row>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收货人姓名">
              {{ info.consigneeName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人电话">
              {{ info.consigneeTel }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人地址">
              {{ info.consigneeFullAddress }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section
      class="info-container"
      :class="logisticsList.length > 0 ? '' : 'commodity-container'"
    >
      <a-row>
        <a-col><div class="info-title">商品信息</div></a-col>
      </a-row>
      <section v-for="(item, index) in monthSkuList" :key="index">
        <a-table
          class="commodity-table"
          :data-source="item.list"
          :columns="tableColumns"
          :loading="loading"
          row-key="skuId"
          :pagination="false"
        >
          <div
            slot="skuImages"
            v-viewer
            slot-scope="text, record"
            class="img-container"
          >
            <div
              v-if="record.fileList && record.fileList.length > 0"
              class="img-container"
            >
              <img
                v-if="!record.fileList[0].thumbUrl"
                :src="imageBaseUrl + record.fileList[0].fileUrl"
                :data-source="imageBaseUrl + record.fileList[0].fileUrl"
                alt="产品图片"
                class="img-sku"
              />
              <img
                v-else
                :src="imageBaseUrl + record.fileList[0].thumbUrl"
                :data-source="imageBaseUrl + record.fileList[0].fileUrl"
                alt="产品图片"
                class="img-sku"
              />
            </div>
          </div>
          <template v-if="info.orderType !== '即时订单'" slot="title">
            {{ item.title }}
          </template>
          <template slot="deliveryQuantity" slot-scope="text, record">
            <span v-if="record.deliveryQuantity">
              {{ record.deliveryQuantity }}
            </span>
            <span v-else>0</span>
          </template>
        </a-table>
      </section>
    </section>
    <section v-if="logisticsList.length > 0" class="commodity-container">
      <div v-if="logisticsList.length === 1">
        <div class="logistics">
          <div class="info-title">物流信息</div>
          <a-button @click="onDeliveryDetail(logisticsList[0].logisticsId)">
            查看发货明细
          </a-button>
        </div>
        <a-row>
          <a-col :span="12" class="col">
            物流公司：{{
              logisticsList[0].logisticsName
                ? logisticsList[0].logisticsName
                : "-"
            }}
          </a-col>
          <a-col :span="12" class="col">
            快递单号：{{
              logisticsList[0].logisticsSn ? logisticsList[0].logisticsSn : "-"
            }}
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12" class="col">
            我的发货信息：{{ logisticsList[0].senderAddress }}
          </a-col>
          <a-col :span="12" class="col">
            联系电话：{{
              logisticsList[0].logisticsTel
                ? logisticsList[0].logisticsTel
                : "-"
            }}
          </a-col>
        </a-row>
        <div class="line"></div>
      </div>
      <a-row v-if="logisticsList.length > 1">
        <a-col><div class="info-title">发货信息</div></a-col>
      </a-row>
      <div v-if="logisticsList.length > 1">
        <div
          v-for="(item, index) in logisticsList"
          :key="index"
          class="deliver-item"
        >
          <div class="item-title">
            第{{ toChinesNum(index + 1) }}批
            <a-button
              class="btn-detail"
              @click="onDeliveryDetail(item.logisticsId)"
            >
              查看发货明细
            </a-button>
          </div>
          <a-row>
            <a-col :span="12" class="col">
              物流公司：{{ item.logisticsName ? item.logisticsName : "-" }}
            </a-col>
            <a-col :span="12" class="col">
              快递单号：{{ item.logisticsSn ? item.logisticsSn : "-" }}
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" class="col">
              我的发货信息：{{ item.senderAddress }}
            </a-col>
            <a-col :span="12" class="col">
              联系电话：{{ item.logisticsTel ? item.logisticsTel : "-" }}
            </a-col>
          </a-row>
          <div class="line"></div>
        </div>
      </div>
    </section>
    <div class="examine-container">
      <div class="button-container">
        <div v-if="!isFromPd">
          <a-button
            v-if="info.isThereAnAssociatedOrder"
            type="primary"
            class="button-item"
            @click="onCheckAssociatedOrder"
          >
            查看关联订单
          </a-button>
          <a-button
            v-if="
              info.orderStatus === '已支付' ||
                info.orderStatus === '待发货' ||
                info.orderStatus === '部分发货' ||
                info.orderStatus === '已导入生产系统' ||
                info.orderStatus === '待生产' ||
                info.orderStatus === '生产中'
            "
            type="primary"
            class="button-item"
            @click="onDeliverGoods"
          >
            发货
          </a-button>
        </div>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import {
  fetchUndeliveredDetail,
  onImportProduct
} from "../../services/OrderService";
import { tsFormat } from "../../components/DateUtils";
import { formatList, toChinesNum } from "../../components/utils";
import { imageBaseUrl } from "../../services/HttpService";

const tableColumns = [
  {
    title: "商品编码",
    dataIndex: "skuCode"
  },
  {
    title: "商品名称",
    dataIndex: "skuName"
  },
  {
    title: "商品图",
    dataIndex: "skuImages",
    scopedSlots: { customRender: "skuImages" }
  },
  {
    title: "商品数量",
    dataIndex: "purchaseQuantity"
  },
  {
    title: "已发货数量",
    dataIndex: "deliveryQuantity",
    scopedSlots: { customRender: "deliveryQuantity" }
  },
  {
    title: "装运总体积（m³）",
    dataIndex: "skuVolume"
  },
  {
    title: "商品总重量（kg）",
    dataIndex: "skuWeight"
  }
];
export default {
  data() {
    return {
      toChinesNum,
      imageBaseUrl,
      tsFormat,
      tableData: [],
      tableColumns,
      loading: false,
      procureOrderSn: this.$route.params.procureOrderSn,
      info: {},
      dateMonth: "",
      monthSkuList: [],
      logisticsList: [],
      isFromPd: false
    };
  },
  mounted() {
    this.onUndeliveredOrderDetail();
  },
  methods: {
    onBack() {
      this.$router.push({ name: "UndeliveredOrder" });
    },
    onBackPD() {
      this.$router.push({ name: "SpellList" });
    },
    // 未交付订单详情
    onUndeliveredOrderDetail() {
      this.loading = true;
      fetchUndeliveredDetail(this.procureOrderSn).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.info = data;
          const monthOrderSkuDOList = data.monthOrderSkuDOList;
          const list = [];
          const monthSkuList = [];
          const skuPicMap = data.skuPicMap;
          const skuPicList = [];
          if (skuPicMap) {
            for (const key in skuPicMap) {
              const params = {
                skuId: key,
                imgList: skuPicMap[key]
              };
              skuPicList.push(params);
            }
          }
          if (monthOrderSkuDOList) {
            for (const key in monthOrderSkuDOList) {
              list.push(key);
              const params = {
                title: key,
                list: monthOrderSkuDOList[key]
              };
              monthSkuList.push(params);
            }
            this.dateMonth = formatList(list);
            if (monthSkuList.length > 0) {
              monthSkuList.forEach(item => {
                console.log(monthSkuList);
                if (item.list && item.list.length > 0) {
                  item.list.forEach(e => {
                    e.keyProps = JSON.parse(e.keyProps);
                    if (skuPicList.length > 0) {
                      skuPicList.forEach(ele => {
                        if (ele.skuId === e.skuId) {
                          if (ele.imgList.length > 0) {
                            e.skuImage = ele.imgList[0];
                          }
                        }
                      });
                    }
                  });
                }
              });
            }
            this.monthSkuList = monthSkuList;
          }
          this.logisticsList = data.logisticsVOList;
          if (this.logisticsList.length > 0) {
            this.logisticsList.forEach(item => {
              item.senderAddress = `${item.senderFullAddress}${item.senderDetailAddress},${item.senderName},${item.senderTel}`;
            });
          }
        }
      });
    },
    // 查看关联订单
    onCheckAssociatedOrder() {
      this.$router.push({
        name: "AssociatedOrder",
        params: { orderSn: this.procureOrderSn }
      });
    },
    // 跳转发货页面
    onDeliverGoods() {
      this.$router.push({
        name: "DeliverGoods",
        params: { orderSn: this.procureOrderSn }
      });
    },
    // 查看发货明细
    onDeliveryDetail(logisticsId) {
      this.$router.push({
        name: "DeliveryDetails",
        params: { logisticsId: logisticsId, orderSn: this.procureOrderSn }
      });
    },
    // 导入生产系统
    onImportProductionSystem() {
      const params = {
        orderSn: this.procureOrderSn,
        status: "待生产"
      };
      onImportProduct(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          this.onUndeliveredOrderDetail();
          this.$message.success("导入成功");
        }
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "SpellList") {
        vm.isFromPd = true;
      }
    });
  }
};
</script>

<style scoped>
.info-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 0 20px;
  background-color: white;
}
.commodity-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 60px 20px;
  background-color: white;
}
.info-title {
  font-weight: bold;
  margin-bottom: 10px;
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: left;
  min-width: 100px !important;
}
/deep/ .ant-form-item-control {
  width: 90%;
}
.commodity-table {
  margin-top: 20px;
  margin-bottom: 20px;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
.button-container {
  display: flex;
}
.button-item {
  margin-right: 15px;
}
/deep/ .ant-table-title {
  display: flex;
  justify-content: center;
  background-color: rgba(55, 105, 255, 0.1);
}
.img-container {
  width: 100px;
  height: 100px;
}
.img-sku {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.deliver-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.item-title {
  box-sizing: border-box;
  padding: 12px 20px;
  background-color: #ecf0fe;
  display: flex;
  justify-content: center;
  position: relative;
}
.btn-detail {
  position: absolute;
  right: 20px;
  top: 7.5px;
}
.col {
  box-sizing: border-box;
  padding: 15px 0;
}
.line {
  height: 0.1px;
  background-color: rgba(0, 0, 0, 0.2);
}
.venu-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.venu-name a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.65);
  cursor: default;
}
.logistics {
  display: flex;
  justify-content: space-between;
}
</style>
